import styled from 'styled-components'
import party from 'party-js'
import AchievementPopup from './AchievementPopup'
import caretRight from './images/caret-right.svg'
import clock from './images/clock.svg'
import star from './images/star.svg'
import apiClient from './utils/ApiClient'
import { useState, useRef, forwardRef } from 'react'

const Container = styled.div`
	height: 253px;
	border: 2px solid var(--color-medium-gray);
	background-color: white;
	border-radius: 8px;
	display: flex;
	flex-flow: column nowrap;
	align-items: stretch;
	justify-content: flex-start;
	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
	text-align: center;
`

const Content = styled.div`
	flex-grow: 1;
	padding: 16px;
	padding-bottom: ${props => props.showTimeRemaining ? '16px' : '9px'};
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: flex-start;
`

const Icon = styled.div`
	--progress-color: ${(props) =>
		props.completed ? 'var(--color-green)'
		: props.claimable ? 'var(--color-blue)'
		: props.activated ? 'var(--color-red)'
		: 'var(--color-dark-gray)'};
	--progress-step: ${(props) => {
		if (!props.activated || props.completed) return '1turn'
		return Math.min(props.progress / props.goal, 1) + 'turn'
	}};
	background-color: var(--color-medium-gray);
	background-image: conic-gradient(
		var(--progress-color) var(--progress-step),
		transparent var(--progress-step)
	);
	height: 94px;
	width: 94px;
	padding: 6px;
	border-radius: 50%;
`

const IconImage = styled.img`
	height: 100%;
	width: 100%;
	background-color: white;
	border-radius: 50%;
  filter: grayscale(${props => props.activated ? 0 : 1});
  transition: filter 0.5s ease-in;
`

const Title = styled.div`
	margin-top: 16px;
	margin-bottom: auto;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
	gap: 5px;
	cursor: pointer;
`

const RewardValue = styled.div`
	height: 22px;
	padding: 5px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
	gap: 3px;
	border-radius: 9999px;
	background-color: var(--color-light-gray);
`

const Button = styled.button`
	height: 28px;
	margin-top: 16px;
	padding: 0 12px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
	text-align: center;
	white-space: nowrap;
	border: none;
	border-radius: 9999px;
	color: white;
	font: inherit;
`

const ActivateButton = styled(Button)`
	background-color: var(--color-dark-gray);
	cursor: pointer;
`

const ClaimButton = styled(Button)`
	background-color: var(--color-blue);
	cursor: pointer;
`

const CompletedPill = styled(Button).attrs((props) => ({
	as: 'div',
}))`
	background-color: var(--color-green);
`

const TimeRemaining = styled.div`
	padding: 9px 0;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
	gap: 9px;
	background-color: var(--color-light-gray);
	color: var(--color-dark-gray);
`

export default forwardRef(({
	id,
	title,
	description,
	rewardValue,
	activated,
	completedAt,
	claimedAt,
	timeRemaining,
	progress,
	goal,
	imageUri,
	userId
}, ref) => {

	const [_activated, setActive] = useState(activated);
	const [_claimedAt, setClaimedAt] = useState(claimedAt);
  	const popupRef = useRef(null)

	async function onActivate(event) {
    	party.sparkles(event.target)

		// make sure effect has time to fire
		await (() => new Promise(resolve => setTimeout(resolve, 50)))()

		try {
			console.log('url: achievement/'+id+'/activate/'+userId)
			const result = await apiClient.post('achievement/'+id+'/activate/'+userId)
			console.log(result)
			setActive(true)
			// alert(`Achievement ${id} activated!`)
		} catch (error){
			alert('An error occured during activation...')
		}
	}

	async function onClaim(event) {
    	party.confetti(event.target)
    	// make sure effect has time to fire
    	await (() => new Promise(resolve => setTimeout(resolve, 50)))()

		try {
			const result = await apiClient.post('achievement/'+id+'/claim/'+userId)
			console.log(result)
			setClaimedAt(new Date())
			// alert(`Achievement ${id} claimed!`)
		} catch (error){
		alert('An error occured during claiming reward...')
		}
	}

  function closePopup() {
    if (!popupRef.current) return

    popupRef.current.addEventListener('animationend', () => {
      popupRef.current.close()
      popupRef.current.classList.remove('close')
    }, { once: true })

    popupRef.current.classList.add('close')
  }

  let button = null
  if (!_activated) button = <ActivateButton onClick={onActivate}>Activate</ActivateButton>
  else if (completedAt && !_claimedAt) button = <ClaimButton onClick={onClaim}>Claim Reward!</ClaimButton>
  else if (_claimedAt) button = <CompletedPill>Achieved</CompletedPill>

  const showTimeRemaining = _activated && !_claimedAt && !completedAt

	return (
		<Container ref={ref}>
			<Content showTimeRemaining={showTimeRemaining}>
				<Icon activated={_activated} progress={progress} claimable={!!completedAt} goal={goal} completed={!!_claimedAt}>
					<IconImage src={imageUri} alt="Achievement icon" activated={_activated} />
				</Icon>
				<Title onClick={() => popupRef.current?.showModal()}>
					{title} <img src={caretRight} alt="" />
				</Title>
				<RewardValue>
					+{rewardValue} <img src={star} alt="" />
				</RewardValue>
				{button}
			</Content>
			{showTimeRemaining ? (
				<TimeRemaining>
					<img src={clock} alt="" /> {timeRemaining} left
				</TimeRemaining>
			) : null}

      <AchievementPopup
        ref={popupRef}
        close={closePopup}
        title={title}
        description={description}
        timeRemaining={timeRemaining}
        button={button}
      />
		</Container>
	)
})
