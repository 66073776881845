import { useRef, forwardRef } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import styled from 'styled-components'
import FlipMove from 'react-flip-move'
import AchievementCard from './AchievementCard'
import FeaturedAchievementCard from './FeaturedAchievementCard'
import apiClient from './utils/ApiClient'
import dayjs from 'dayjs'

const mockedAchievements = [
	{
		id: 1,
		title: 'Shop Til You Drop',
		description: '',
		reward: '2x',
		progressAmount: 0,
		goalAmount: 3,
		activated: false,
		completed: false,
		type: 'featured',
		imageUri: 'https://haxtra.a.extra.app/movie.png',
	},
	{
		id: 2,
		title: 'Gas Category',
		description:
			'Earn 100 points after completing 3 transactions using your Extra Card on rideshares\n\nValid once for use with Uber, Lyft or other rideshare services. See Extra Terms of Service of details.',
		reward: '2x',
		progressAmount: 1,
		goalAmount: 3,
		activated: true,
		completed: false,
		timeRemaining: '7 days',
		type: 'featured',
	},
	{
		id: 3,
		title: 'Gas Category',
		description:
			'Earn 100 points after completing 3 transactions using your Extra Card on rideshares\n\nValid once for use with Uber, Lyft or other rideshare services. See Extra Terms of Service of details.',
		reward: '2x',
		progressAmount: 2,
		goalAmount: 3,
		activated: false,
		completed: false,
		timeRemaining: '7 days',
		type: 'featured',
	},
	{
		id: 4,
		title: 'Gas Category',
		description:
			'Earn 100 points after completing 3 transactions using your Extra Card on rideshares\n\nValid once for use with Uber, Lyft or other rideshare services. See Extra Terms of Service of details.',
		reward: '2x',
		progressAmount: 3,
		goalAmount: 3,
		activated: true,
		completed: false,
		timeRemaining: '7 days',
		type: 'featured',
	},
	{
		id: 5,
		title: 'Need a ride?',
		description:
			'Earn 100 points after completing 3 transactions using your Extra Card on rideshares\n\nValid once for use with Uber, Lyft or other rideshare services. See Extra Terms of Service of details.',
		reward: 100,
		progressAmount: 10,
		goalAmount: 20,
		activated: false,
		completed: false,
		timeRemaining: '7 days',
		type: 'boost',
	},
	{
		id: 6,
		title: 'Need a ride?',
		description:
			'Earn 100 points after completing 3 transactions using your Extra Card on rideshares\n\nValid once for use with Uber, Lyft or other rideshare services. See Extra Terms of Service of details.',
		reward: 100,
		progressAmount: 10,
		goalAmount: 20,
		activated: true,
		completed: false,
		timeRemaining: '7 days',
		type: 'boost',
	},
	{
		id: 7,
		title: 'Need a ride?',
		description:
			'Earn 100 points after completing 3 transactions using your Extra Card on rideshares\n\nValid once for use with Uber, Lyft or other rideshare services. See Extra Terms of Service of details.',
		reward: 100,
		progressAmount: 10,
		goalAmount: 20,
		activated: true,
		completed: true,
		timeRemaining: '7 days',
		type: 'boost',
	},
]

const Page = styled.div`
	display: flex;
	flex-direction: column;
`
const FeaturedContainter = styled.div`
	display: flex;
	flex-direction: column;
	padding-top: 16px;
	width: 100%;
`
const Featured = styled.span`
	font-weight: bold;
	align-self: flex-start;
	padding-left: 16px;
`
const Carousel = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	gap: 10px;
	overflow-x: scroll;
	padding: 16px;
	white-space: nowrap;
	&::-webkit-scrollbar {
		display: none;
	}
`
const BoostContainter = styled.div`
	display: flex;
	flex-direction: column;
	align-self: flex-start;
	align-items: center;
	justify-content: flex-start;
	padding: 16px;
	gap: 10px;
	width: 100%;
`
const Boosts = styled.span`
	font-weight: bold;
	align-self: flex-start;
	padding-bottom: 16px;
`

const BoostsGrid = styled(FlipMove)`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fill, 150px);
	justify-content: center;
	gap: 19px 15px;
`

function setCarouselItems(achievement) {
	const userId = extractUserIdFromURL()
	let mode = 'days'
	if (dayjs(achievement.expiresAt).diff(dayjs(), 'day') < 2) mode = 'hours'
	return (
		<FeaturedAchievementCard
			id={achievement.achievementId}
			key={achievement.achievementId}
			title={achievement.title}
			description={achievement.description}
			rewardValue={achievement.reward}
			progress={achievement.progressAmount}
			goal={achievement.goalAmount}
			activated={achievement.activated}
			completedAt={achievement.completedAt}
			claimedAt={achievement.claimedAt}
			timeRemaining={Math.max(dayjs(achievement.expiresAt).diff(dayjs(), mode), 0) + ' ' + mode}
			imageUri={achievement.imageUri}
			userId={userId}
		/>
	)
}

function setBoostItems(achievement) {
	const userId = extractUserIdFromURL()
	let mode = 'days'
	if (dayjs(achievement.expiresAt).diff(dayjs(), 'day') < 2) mode = 'hours'
	return (
		<AchievementCard
			id={achievement.achievementId}
			key={achievement.achievementId}
			title={achievement.title}
			description={achievement.description}
			rewardValue={achievement.reward}
			progress={achievement.progressAmount}
			goal={achievement.goalAmount}
			activated={achievement.activated}
			completedAt={achievement.completedAt}
			claimedAt={achievement.claimedAt}
			timeRemaining={Math.max(dayjs(achievement.expiresAt).diff(dayjs(), mode), 0) + ' ' + mode}
			imageUri={achievement.imageUri}
			userId={userId}
		/>
	)
}
let randomUserId
function extractUserIdFromURL() {
	let userId
	//try to get userId from url path
	try {
		userId = parseInt(window.location.pathname.substring(1))
	} catch (error) {
		console.error('error while fetching userId from URL')
		console.error('try providing user_id as path parameter like /1234')
		console.error(error.message)
	}
	//if path fails, try query parameter
	if (!userId) {
		const params = new URLSearchParams(window.location.search)
		const userParam = params.get('userId')
		if (userParam) userId = parseInt(userParam)
	}
	//if still having issue, returns a random user_id for testing
	if (!userId) {
		if (!randomUserId) randomUserId = Math.floor(Math.random() * 10000)
		userId = randomUserId
	}
	console.log(`user_id ${randomUserId && '(random)'}:`, userId)
	return userId
}

//custom hook method for polling
function useInterval(callback, delay) {
	const savedCallback = useRef()

	// Remember the latest callback.
	useEffect(() => {
		savedCallback.current = callback
	}, [callback])

	// Set up the interval.
	useEffect(() => {
		function tick() {
			savedCallback.current()
		}
		if (delay !== null) {
			let id = setInterval(tick, delay)
			return () => clearInterval(id)
		}
	}, [delay])
}

function AchievementPage() {
	const userId = extractUserIdFromURL()
	const [achievements, setAchievements] = useState([])

	useInterval(() => {
		async function fetchAchievements() {
			const data = await apiClient.get('achievements/' + userId)
			if (data && data?.length > 0) setAchievements(data)
		}
		fetchAchievements()
	}, 2000)

	useEffect(() => {
		async function fetchAchievements() {
			const data = await apiClient.get('achievements/' + userId)
			setAchievements(data)
		}
		fetchAchievements()
	}, [])

	//Order by active >> inactive >> completed
	const achievementSorter = (a, b) => {
		let aVal = 0
		let bVal = 0
		if (a.activated && !a.claimedAt) aVal += 100
		if (b.activated && !b.claimedAt) bVal += 100
		if (!a.activated) aVal += 50
		if (!b.activated) bVal += 50
		if (a.claimedAt) aVal = 0
		if (b.claimedAt) bVal = 0
		return bVal - aVal
	}
	return (
		<Page>
			<FeaturedContainter>
				<Featured>Featured</Featured>
				<Carousel>
					{achievements
						.filter((a) => a.type === 'featured')
						.sort(achievementSorter)
						.map(setCarouselItems)}
				</Carousel>
			</FeaturedContainter>
			<BoostContainter>
				<Boosts>Boosts</Boosts>
				<BoostsGrid>
					{achievements
						.filter((a) => a.type === 'boost')
						.sort(achievementSorter)
						.map(setBoostItems)}
				</BoostsGrid>
			</BoostContainter>
		</Page>
	)
}

export default AchievementPage
