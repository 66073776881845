const API_URL = 'https://api.haxtra.a.extra.app/'

class ApiClient {
	async call(url, body = null, method = 'GET', options) {
		try {
			const result = await fetch(API_URL + url, {
				method,
				body,
				cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
				...options,
			})
			const data = await result.json()
			console.log('result:', data)
			return data
		} catch (error) {
			console.log('error', error)
			//alert(error?.message || 'Error occured! Try again...') //tiny error handling logic for hackathon :)
		}
	}

	async get(url, options) {
		return this.call(url, null, 'GET', options)
	}

	async post(url, body, options) {
		return this.call(url, body, 'POST', options)
	}

	async put(url, body, options) {
		return this.call(url, body, 'PUT', options)
	}

	async delete(url, body, options) {
		return this.call(url, body, 'DELETE', options)
	}
}

const apiClient = new ApiClient()
export default apiClient
