import { forwardRef } from 'react'
import styled, { keyframes } from 'styled-components'
import clock from './images/clock.svg'
import closeImage from './images/close.svg'

const fadeIn = keyframes`
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
`

const fadeOut = keyframes`
  from {
    opacity: 1;
  } to {
    opacity: 0;
  }
`

const Container = styled.dialog`
	width: 90vw;
	max-width: 500px;
	padding: 28px 20px;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: flex-start;
	border: none;
	border-radius: 8px;
	background-color: white;

	&[open] {
		display: flex;
		animation: ${fadeIn} 0.25s ease;
	}

	&::backdrop {
		backdrop-filter: blur(5px) brightness(0.5);
		animation: ${fadeIn} 0.25s ease;
	}

	&.close {
		animation: ${fadeOut} 0.25s ease;

		&::backdrop {
			animation: ${fadeOut} 0.25s ease;
		}
	}
`

const CloseButton = styled.button`
	margin: -28px -20px;
	padding: 28px 20px;
	align-self: flex-end;
	border: none;
	background-color: transparent;
	cursor: pointer;

	&:focus {
		outline: none;
	}
`

const CloseButtonImage = styled.img`
	width: 11.67px;
	height: 10.5px;
`

const Title = styled.div`
	margin-top: 28px;
	font-size: 16px;
	line-height: 20px;
`

const Description = styled.div`
	&:not(:empty) {
		margin-top: 16px;
	}

	margin-bottom: 17px;
	white-space: pre-line;
	font-weight: normal;
	color: var(--color-description);
`

const TimeRemaining = styled.div`
	margin-top: 8px;
	padding: 9px 28px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
	gap: 9px;
	background-color: var(--color-light-gray);
	color: var(--color-dark-gray);
	border-radius: 6px;
`

export default forwardRef(({ close, title, description, timeRemaining, button }, ref) => {
	return (
		<Container ref={ref}>
			<CloseButton onClick={close}>
				<CloseButtonImage src={closeImage} alt="Close" />
			</CloseButton>
			<Title>{title}</Title>
			<Description>{description}</Description>
			{button ?? (
				<TimeRemaining>
					<img src={clock} alt="" /> {timeRemaining} left
				</TimeRemaining>
			)}
		</Container>
	)
})
